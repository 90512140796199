import React from 'react'

import styled from 'styled-components'
import colors from 'src/assets/styles/colors'
import mediaQuery from 'src/assets/styles/mediaQuery'
import typographySizes from 'src/assets/styles/typographySizes'
import MainWrapper from 'src/components/global/MainWrapper.js'
import TextBarAnimation from '/src/components/global/TextBarAnimationBig.js'

import Lines from 'src/components/global/lines/Lines'
import Seo from 'src/components/global/Seo.js'

import TitleH1 from 'src/components/global/texts/TitleH1.js'
import { Helmet } from 'react-helmet'

const StyledMain = styled.main`
  background-color: ${colors.black};
  width: 100vw;

  position: relative;
  height: 100%;
  padding-top: 70rem;
  ${MainWrapper} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 125rem;
  }
  ${TitleH1} {
    width: 80vw;
    max-width: 900rem;
    text-align: center;
    z-index: 10;
    font-size: ${typographySizes.m}rem;
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: ${typographySizes.m}rem;
      width: calc(100% - 20rem);
    }
    a {
      font-size: inherit;
      color: inherit;
      :hover {
        color: ${colors.mainColor};
      }
    }
  }
`

const externalServer = 'https://nbct-booking-backend.herokuapp.com'
const css1 = externalServer + '/static/nbctbooking.css'
const jsfile = externalServer + '/static/nbctbooking.js'

const IndexPage = () => {
  return (
    <>
      <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <link href={css1} rel="stylesheet" type="text/css" />
        <script src={jsfile} defer />
      </Helmet>
      <Seo title="REZERWACJA" description="REZERWACJA" />
      <StyledMain>
        <TextBarAnimation>Zarezerwuj termin</TextBarAnimation>
        <br />
        <MainWrapper>
          <Lines
            leftMargin
            left
            leftCenter
            center
            rightCenter
            right
            rightMargin
          />
          <div id="nbctbooking"></div>
        </MainWrapper>
      </StyledMain>
    </>
  )
}

export default IndexPage
